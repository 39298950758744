<template>
<!-- 我的话单页面 -->
  <div class="my-bill">
    <!-- 筛选区域 -->
    <div class="screening">
       <el-dialog
        title="查询"
        v-model="theQueryDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="580px"
      >
        <div class="">
          <el-form
            label-position="left"
            label-width="100px"
            size="small"
            :model="searchForm"
            ref="upform"
          >
            <div class="form-out-box p-0-30">
              <el-form-item label="服务商：" prop="">
                <el-select
                  v-model="searchForm.amountId"
                  placeholder="请选择服务商"
                  style="width: 100%"
                  clearable
                  size="mini"
                >
                  <el-option
                    v-for="(item, index) in amount_id"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="本地绑定ID：" prop="">
                <el-input size="mini" v-model="searchForm.bindId" placeholder="请输入本地绑定ID"></el-input>
              </el-form-item>
              <el-form-item label="主叫号码：" prop="">
                <el-input v-model="searchForm.telA" size="mini" placeholder="请输入主叫号码"></el-input>
              </el-form-item>
              <el-form-item label="企&ensp;业&ensp;号：" prop="">
                <el-input v-model="searchForm.telX" size="mini" placeholder="请输入企业号码"></el-input>
              </el-form-item>
              <el-form-item label="被叫号码：" prop="">
                <el-input v-model="searchForm.telB" size="mini" placeholder="请输入被叫号码"></el-input>
              </el-form-item>
              <el-form-item label="通话状态：" prop="">
                <el-select
                  v-model="searchForm.callStatus"
                  placeholder="请选择通话状态"
                  style="width: 100%"
                  clearable
                  size="mini"
                >
                  <el-option
                    v-for="(item, index) in ['未接通','已接通']"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="结束状态：" prop="">
                <el-select
                  v-model="searchForm.finishStatus"
                  placeholder="请选择结束状态"
                  style="width: 100%"
                  clearable
                  size="mini"
                >
                  <el-option
                    v-for="(item, index) in ['主叫挂断','被叫挂断','主叫放弃','被叫无应答','其他状态']"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="通话时间：">
                <el-date-picker
                  v-model="valueTimeCreate"
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 100%"
                  @change="selectTime"
                  value-format="YYYY-MM-DD"
                  :default-time="defaultTime"
                  :clearable="false"
                  size="mini">
                </el-date-picker>
              </el-form-item>
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="searchListFun" round>
                  查询
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
       </el-dialog>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name"></div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>
          <div class="ml-10">
            <el-button class="button-el" size="medium" @click="heavyPush=true">话单重推</el-button>
          </div>
          <div class="ml-10">
            <el-dropdown>
              <el-button class="button-el" size="medium">
                <icon-font type="icon-daochushuju" class="button-icon-font"/>
                导出数据
                <icon-font type="icon-copy-4-36" class="button-icon-font"/>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- <el-dropdown-item @click="fileExport(0)">全部</el-dropdown-item> -->
                  <!-- <el-dropdown-item @click="fileExport(1)">选定</el-dropdown-item> -->
                  <el-dropdown-item @click="fileExport(2)">搜索结果</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 数据展示 -->
    <el-row>
      <el-col>
        <div style="overflow-x:auto" class="newTable">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            size="mini"
            tooltip-effect="dark"
            style="width: 100%; overflow: auto"
            :row-style="{ height: '50px' }"
            :cell-style="{ padding: '0px' }"
            height="100%"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <!-- <el-table-column label="绑定ID" width="100px">
              <template #default="scope">{{
                scope.row.id == null ? "-" : scope.row.id
              }}</template>
            </el-table-column> -->
            <!-- <el-table-column label="上游绑定ID" width="170px">
              <template #default="scope">{{
                scope.row.subId == null ? "-" : scope.row.subId
              }}</template>
            </el-table-column> -->
           <!--  <el-table-column label="通话ID" width="140px">
              <template #default="scope">{{
                scope.row.callId == null ? "-" : scope.row.callId
              }}</template>
            </el-table-column> -->
            <el-table-column label="服务商">
              <template #default="scope">
                <span>{{ amountText(scope.row.amountId) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="绑定ID">
              <template #default="scope">
                <span>{{ scope.row?.bindId == null ? "-" : scope.row?.bindId }}</span>
              </template>
            </el-table-column>
            <el-table-column label="主叫号码">
              <template #default="scope">{{
                scope.row.telA == null ? "-" : scope.row.telA
              }}</template>
            </el-table-column>
            <el-table-column label="企业号">
              <template #default="scope">{{
                scope.row.telX == null ? "-" : scope.row.telX
              }}</template>
            </el-table-column>
            <el-table-column label="被叫号码">
              <template #default="scope">{{
                scope.row.telB == null ? "-" : scope.row.telB
              }}</template>
            </el-table-column>
            <!-- <el-table-column label="回调地址" width="140px">
              <template #default="scope">{{
                scope.row.notifyUrl == null ? "-" : scope.row.notifyUrl
              }}</template>
            </el-table-column>
            <el-table-column label="回调参数" width="110px">
              <template #default="scope">{{
                scope.row.notifyData == null ? "-" : scope.row.notifyData
              }}</template>
            </el-table-column> -->
            <el-table-column label="通话时间" width="200">
              <template #default="scope">
                <span>开始：{{ scope.row.startTime == null ? "-" : scope.row.startTime }}</span>
              </template>
            </el-table-column>
            <el-table-column label="通话时间" width="200">
              <template #default="scope">
                  <span>结束：{{ scope.row.endTime == null ? "-" : scope.row.endTime }}</span>
              </template>
            </el-table-column>
            <el-table-column label="通话时长(秒)">
              <template #default="scope">{{
                scope.row.duration == null||scope.row.duration == 0 ? "-" : scope.row.duration
              }}</template>
            </el-table-column>
            <el-table-column label="话单消费">
              <template #default="scope">{{
                scope.row.price == null||scope.row.duration == 0 ? "-" : scope.row.price
              }}</template>
            </el-table-column>
            <!-- <el-table-column label="添加/更新" width="240">
              <template #default="scope">
                <div class="grid">
                  <p>添加：{{ scope.row.createTime == null ? "-" : scope.row.createTime }}</p>
                  <p>更新：{{ scope.row.updateTime == null ? "-" : scope.row.updateTime }}</p>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column label="通话状态">
              <template #default="scope">
                <span :class="scope.row.callStatus == 0?'no-red':'yes-green'">{{scope.row.callStatus == null ? "-" : scope.row.callStatusText }}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束状态">
              <template #default="scope">{{
                scope.row.finishStatus == null||scope.row.finishStatus == 4? "-" : scope.row.finishStatusText
              }}</template>
            </el-table-column>
            <el-table-column label="录音" width="160px">
              <template #default="scope">
                <span  v-if="scope.row.recordUrl != null&&scope.row.recordUrlType == null" @click="pullAway(scope.row)" type="success" class="button-font-color">
                  <icon-font type="icon-a-zu1512"/>
                  获取录音
                </span>
                <span  v-if="scope.row.recordUrlType == 1||scope.row.recordUrlType == 3" type="success" class="button-font-color">
                  <i class="el-icon-loading loding-color-ly"></i>
                  正在获取...
                </span>
                <audio
                  v-if="scope.row.recordUrlType == 2"
                  class="recordSon"
                  :src="scope.row.recordUrl"
                  controls="controls"
                  preload="load"
                ></audio>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[100, 200, 300, 400]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="话单重推" v-model="heavyPush" width="600px" :destroy-on-close="true" :close-on-click-modal="false">
        <div class="center-body">
          <div class="form-out-box p-0-30">
            <el-form label-position="left" label-width="120px" :model="heavyPushform" ref="heavyPushform" size="mini"
              :rules="rules">
          
              <el-form-item label="主叫号码：" prop="">
                <el-input v-model="heavyPushform.telA" class="input-searchFrom-width" size="mini" placeholder="请输入主叫号码">
                </el-input>
              </el-form-item>
              <el-form-item label="企&ensp;业&ensp;号：" prop="">
                <el-input v-model="heavyPushform.telX" class="input-searchFrom-width" size="mini" placeholder="请输入企业号码">
                </el-input>
              </el-form-item>
              <el-form-item label="被叫号码：" prop="">
                <el-input v-model="heavyPushform.telB" class="input-searchFrom-width" size="mini" placeholder="请输入被叫号码">
                </el-input>
              </el-form-item>
              <el-form-item label="服务商：" prop="">
                <el-select v-model="heavyPushform.amountId" class="input-searchFrom-width" placeholder="请选择服务商"
                  style="width: 100%" clearable filterable @change="changeValues" size="mini">
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="接口类型：" prop="">
                <el-select v-model="heavyPushform.interfaceId" class="input-searchFrom-width" placeholder="请选择接口类型"
                  style="width: 100%" clearable size="mini">
                  <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="推送地址：" prop="">
                <el-input class="input-searchFrom-width" placeholder="请输入推送地址" v-model="heavyPushform.pushUrl"
                  size="mini"></el-input>
              </el-form-item>
            
              <el-form-item label="是否推送：" prop="">
                <el-radio-group v-model="heavyPushform.type">
                  <el-radio :label="2">录音</el-radio>
                  <el-radio :label="1">话单</el-radio>
                  <el-radio :label="0">录音和话单</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="通话开始时间：" prop="heavyPushTime">
                <el-date-picker class="input-searchFrom-width" v-model="heavyPushform.heavyPushTime"
                  type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="YYYY-MM-DD HH:mm:ss" :default-time="defaultTime">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button class="cancel" plain @click="heavyPush=false" round>取消</el-button>
                <el-button class="confirmAdd" @click="addHeavyPush('heavyPushform')" round>
                  开始重推</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
  </div>
</template>
<script>
import bill from '@/api/open/privacy/bill'
import interfacets from '@/api/open/privacy/interface'
import amount from '@/api/open/privacy/amount'
import { IconFont } from "@/utils/iconfont";
import { ElMessage } from 'element-plus'
import { message } from "ant-design-vue";
import { getDate } from '@/utils/timeToChoose.js'
export default {
  name:"MyBill",
  components:{
    IconFont
  },
  data(){
    return{
      rules: {
        heavyPushTime: {
          required: true,
          message: '请选择开始时间', trigger: 'blur' 
        },
      },
      heavyPushform: {
        uid: '',
              pushUrl: '',
              qps: '',
    heavyPushTime:[],
            telA:'',
             telX:'',
       telB:'',
        amountId:'',
             interfaceId:'',

        type: 2,
      },
      heavyPush:false,
      allIds:[],
      multipleSelection:[],
      theQueryDialog:false,
      tableData:[],  //当前页面数据
      activeNames: ['1'], //折叠面板
      loading: true, // loding 状态启用
      tableHeight: "", //表格高度
      // operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, //分页
      total: 0, //总数据条数
      interface_id: [], // 拿到的接口类型
      amount_id: [], // 拿到的通信账户类型
      valueTimeCreate:[],//选中时间段
      valueTimeAction:[],//选中时间段
      defaultTime: [
        new Date(2000, 1, 1),
        new Date(2000, 2, 1)
      ], //默认时间 '12:00:00', '08:00:00'
      searchForm:{  // 搜索项
        bindId:'', // 本地绑定ID
        page:1, // 页数
        pageSize:100,  // 每一页数据量
        uid:'', // 用户ID
        callId:'', // 通话ID
        subId:'', // 上游绑定ID
        interfaceId:'', // 接口类型
        amountId:'',  // 通信账户ID
        telA:'',  // 主叫
        telX:'',  // 企业号
        telB:'',  // 被叫
        createTimeBegin:'', // 创建开始时间
        createTimeEnd:'',   // 创建结束时间
        startTimeBegin:'',  // 开始时间
        startTimeEnd:'',    // 结束时间
        callStatus:'',  // 通话状态（0:未接通 1:已接通）
        finishStatus:null,  // 结束状态(0:主叫挂断 1:被叫挂断 2:主叫放弃 3:被叫无应答 4:其他状态)
        auditStatus:'',  // 审核状态（0:未审核 1:审核通过 2:审核不通过）
      },
    }
  },
  mounted(){
    this.searchForm.createTimeBegin = getDate(0);
    this.searchForm.createTimeEnd = getDate(0);
    this.valueTimeCreate[0] = getDate(0)
    this.valueTimeCreate[1] = getDate(0)
    this.getDataList();
    this.getApiType();
    this.getAmountType();
    this.tableHeight = this.$store.state.tableHeight;
  },
  methods:{
        // 重推
        addHeavyPush(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // reEsPushBill rePushBill
          bill
            .pushbill({
              uid: this.heavyPushform.uid,
              pushUrl: this.heavyPushform.pushUrl,
              qps: this.heavyPushform.qps,
              type: this.heavyPushform.type,
              startTime: this.heavyPushform.heavyPushTime[0],
              endTime: this.heavyPushform.heavyPushTime[1],
              telA: this.heavyPushform.telA,
              telX: this.heavyPushform.telX,
              telB: this.heavyPushform.telB,
              amountId: this.heavyPushform.amountId,
              interfaceId: this.heavyPushform.interfaceId,
            })
            .then((res) => {
              if (res.code == 200) {
                ElMessage({
                  message: '话单正在重推中...',
                  type: 'success',
                })
                this.heavyPush = false
              }
            })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    // 条件筛选
    searchListFun(){
      if(this.valueTimeCreate){
        this.searchForm.createTimeBegin = this.valueTimeCreate[0];
        this.searchForm.createTimeEnd = this.valueTimeCreate[1];
      }else{
        this.searchForm.createTimeBegin = '';
        this.searchForm.createTimeEnd = '';
      }
      if(this.valueTimeAction){
        this.searchForm.startTimeBegin = this.valueTimeAction[0];
        this.searchForm.startTimeEnd = this.valueTimeAction[1];
      }else{
        this.searchForm.startTimeBegin = '';
        this.searchForm.startTimeEnd = '';
      }
      if(this.searchForm.finishStatus===''){
        this.searchForm.finishStatus = null;
      }
      this.getDataList();
      this.theQueryDialog = false
    },
    // 条件重置
    searchTapReset(kind){
      this.searchForm = {
        page: this.currentPage,
        pageSize: 100,
      };
      if (kind != 1 || this.valueTimeCreate == null || this.valueTimeCreate.length == 0) {
        this.valueTimeCreate = [getDate(0), getDate(0)]
      }
      this.searchForm.createTimeBegin = this.valueTimeCreate[0]
      this.searchForm.createTimeEnd = this.valueTimeCreate[1]
      this.valueTimeAction = [];
      this.searchForm.finishStatus = null;
      if(kind == 1){
        this.getDataList();
      }
    },
    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.getDataList();
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.currentPage = val;
      this.getDataList();
    },
    // 接口类型汉字
    interfaceText(id) {
      for (let i of this.interface_id) {
        if (i.id === id) {
          return i.name;
        }
      }
    },
    // 通信账户汉字
    amountText(id) {
      if (id == 0) {
        return "主账户";
      } else {
        for (let i of this.amount_id) {
          if (i.id === id) {
            return i.name;
          }
        }
      }
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data;
      });
    },
    // 获取通信账户类型
    getAmountType() {
      amount.getAmountList({}).then((res) => {
        this.amount_id = res.data;
      });
    },
    // 获取当前页面数据
    getDataList(){
      this.loading = true;
      bill.getEsBillList({...this.searchForm}).then(res=>{
        this.tableData = res.data.records;
        this.total = res.data.total;
        if(res.code===200){
          this.loading = false;
        }
      })
    },
    IsLoad(url){
        return new Promise(function (resolve, reject) {
            // 测试链接连通性, 主要检测404错误
            // 由于AJAX通常无法区分404和跨域问题
            // 所以只能用script 或者 link标签
            // link比script更容易捕获错误
            var dom= document.createElement('link');
            dom.href = url;
            dom.rel = 'stylesheet';
            document.head.appendChild(dom);
            dom.onload = function () {
                document.head.removeChild(dom);
                resolve();
            }
            dom.onerror = reject;
        });
    },
    // 拉取
    pullAway(row){
      row.recordUrlType = 1
      row.disabled = false
      this.IsLoad(row.recordUrl).then(function(data){
        //处理resolve的代码
        row.recordUrlType = 2
        // console.log('1',data);
      },function(data){
        //处理reject的代码
        row.recordUrlType = 3
        row.disabled = true
        ElMessage({
          message: '录音同步中，请耐心等待5分钟后再试~',
          type: 'warning',
        })
      })
    },
    // 列表多选--操作
    handleSelectionChange(val) {
      let newIds = [];
      val.forEach((item) => {
        newIds.push(item.id);
      });
      this.multipleSelection = val;
      this.allIds = newIds;
    },
    selectTime(val){
      if(val){
        this.searchForm.startTimeBegin = val[0];
        this.searchForm.startTimeEnd = val[1];
      }else{
        this.searchForm.startTimeBegin = '';
        this.searchForm.startTimeEnd = '';
      }
    },
    // 导出
    fileExport(type){//0,全部 1，选定 2，结果
      if(type == 0){
        message.warning("正在导出...，请在消息模块下载");
        bill.esBillExport().then((res) => {
          if (res.code != 200) {
            message.error(res.message);
          }
        })
      }
      if(type == 1){
        if(this.multipleSelection.length == 0){
          message.warning("请勾选需要导出的条目！");
        }else{
          message.warning("正在导出...，请在消息模块下载");
          bill.esBillExport({
            ids:this.allIds
          }).then((res) => {
            if (res.code != 200) {
              message.error(res.message);
            }
          })
        }
      }
      if(type == 2){
        if(this.valueTimeCreate.length == 0){
          message.warning("请选择日期后再进行导出！");
        }else{
          message.warning("正在导出...，请在消息模块下载");
          bill.esBillExport({
            startTimeBegin:this.valueTimeCreate[0],
            startTimeEnd:this.valueTimeCreate[1],
            amountId:this.searchForm.amountId,
            telA:this.searchForm.telA,
            telX:this.searchForm.telX,
            telB:this.searchForm.telB,
            callStatus:this.searchForm.callStatus,
            finishStatus:this.searchForm.finishStatus,
          }).then((res) => {
            if (res.code != 200) {
              message.error(res.message);
            }
          })
        }
      }
    }
  },
  created(){
    
  }
}
</script>
<style lang="scss" scoped>
.my-bill{
  .grid{
    display: gird;
    height: 60px;
    place-items:center;
  }
}
.recordSon{
  width: 110px;
  height: 32px;
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.newTable{
  height: calc( 100vh - 250px );
}
</style>